var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": _vm.width,
        value: _vm.show,
        fullscreen: _vm.scrollable ? _vm.$vuetify.breakpoint.xs : false,
        "content-class": "core--components--app-modal",
        scrollable: _vm.scrollable,
        persistent: _vm.isPersistent,
      },
      on: { "click:outside": _vm.handleClickOutside },
    },
    [
      _vm.scrollable
        ? _c(
            "v-card",
            {
              staticClass: "py-0 px-0 px-md-0",
              attrs: { color: "bgWalletBlock" },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "justify-center py-5 py-md-8" },
                [
                  _c(
                    "div",
                    { staticClass: "mew-heading-2 text-center pr-6 pr-md-0" },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _vm.hasCloseButton
                    ? _c(
                        "v-btn",
                        {
                          class: _vm.$vuetify.breakpoint.mdAndUp
                            ? "header-close-icon"
                            : "header-close-icon-mobile",
                          attrs: { icon: "" },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                size: "x-large",
                                color: "grey cursor--pointer",
                              },
                              on: { click: _vm.close },
                            },
                            [_vm._v(" mdi-close ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.anchored
                ? _c(
                    "v-card-text",
                    { staticClass: "py-3 px-5 px-md-0" },
                    [
                      _vm._t("dialogBody", function () {
                        return [_c("div", [_vm._v("This is a test value")])]
                      }),
                    ],
                    2
                  )
                : _c(
                    "div",
                    {
                      staticClass: "px-0 px-md-6 pb-2",
                      staticStyle: { "overflow-y": "auto" },
                    },
                    [_vm._t("dialogBody")],
                    2
                  ),
              _c(
                "v-card-actions",
                { staticClass: "py-5 py-md-8" },
                [
                  _vm.hasButtons
                    ? _c(
                        "v-row",
                        {
                          staticClass: "pa-0",
                          attrs: { justify: "space-around", dense: "" },
                        },
                        [
                          !_vm.closeOnly && !_vm.acceptOnly
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: {
                                    cols: "12",
                                    sm: "5",
                                    order: "2",
                                    "order-sm": "1",
                                  },
                                },
                                [
                                  _c("mew-button", {
                                    attrs: {
                                      "btn-style": "outline",
                                      "btn-size": "xlarge",
                                      title: "Cancel",
                                      "has-full-width":
                                        _vm.$vuetify.breakpoint.xs,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.close.apply(null, arguments)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.closeOnly && !_vm.acceptOnly
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "text-left",
                                  attrs: {
                                    cols: "12",
                                    sm: "7",
                                    order: "1",
                                    "order-sm": "2",
                                  },
                                },
                                [
                                  _c("mew-button", {
                                    attrs: {
                                      "btn-size": "xlarge",
                                      title: _vm.btnText,
                                      disabled: !_vm.btnEnabled,
                                      "has-full-width":
                                        _vm.$vuetify.breakpoint.xs,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.btnAction.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.closeOnly
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "text-left",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("mew-button", {
                                    attrs: {
                                      "btn-size": "xlarge",
                                      title: "Close",
                                      "has-full-width": true,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.close.apply(null, arguments)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.acceptOnly
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "text-left",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("mew-button", {
                                    attrs: {
                                      "btn-size": "xlarge",
                                      title: _vm.btnText,
                                      disabled: !_vm.btnEnabled,
                                      "has-full-width": true,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.btnAction.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-sheet",
            { staticClass: "py-6 px-5 px-8 position--relative bgWalletBlock" },
            [
              _vm.hasCloseButton
                ? _c(
                    "v-btn",
                    { staticClass: "header-close-icon", attrs: { icon: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "x-large",
                            color: "grey cursor--pointer",
                          },
                          on: { click: _vm.close },
                        },
                        [_vm._v(" mdi-close ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "header-container" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", "align-self": "center" } },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "mew-heading-2",
                            _vm.titleCenter ? "text-center" : "",
                          ],
                        },
                        [_vm._v(" " + _vm._s(_vm.title) + " ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm._t("dialogBody", function () {
                    return [_c("div", [_vm._v("This is a test value")])]
                  }),
                ],
                2
              ),
              _vm.hasButtons
                ? _c(
                    "v-row",
                    { staticClass: "mt-2", attrs: { justify: "space-around" } },
                    [
                      !_vm.closeOnly
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-right",
                              attrs: {
                                cols: "12",
                                sm: "5",
                                order: "2",
                                "order-sm": "1",
                              },
                            },
                            [
                              _c("mew-button", {
                                attrs: {
                                  "btn-style": "outline",
                                  "btn-size": "xlarge",
                                  title: "Cancel",
                                  "has-full-width": _vm.$vuetify.breakpoint.xs,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.close.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.closeOnly
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-left",
                              attrs: {
                                cols: "12",
                                sm: "7",
                                order: "1",
                                "order-sm": "2",
                              },
                            },
                            [
                              _c("mew-button", {
                                attrs: {
                                  "btn-size": "xlarge",
                                  title: _vm.btnText,
                                  disabled: !_vm.btnEnabled,
                                  "has-full-width": _vm.$vuetify.breakpoint.xs,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.btnAction.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.closeOnly
                        ? _c(
                            "v-col",
                            { staticClass: "text-left", attrs: { cols: "12" } },
                            [
                              _c("mew-button", {
                                attrs: {
                                  "btn-size": "xlarge",
                                  title: "Close",
                                  "has-full-width": true,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.close.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }